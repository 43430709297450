import React from "react";
import { useDispatch } from "react-redux";
import { REMOVE_ITEM, UPDATE_CAR } from "../../../../../reducers/carReducer";

const ButtonMinus = ({ car, id, remove, canDisable }) => {
    const dispatch = useDispatch();
    let canRemove = canDisable && car.find((n) => n.id === id).cantidad === 1
    const onclick = (target) => {
        target.classList.add("minifySVG");
        const carUp = car.map((n) => {
            return { ...n };
        });
        let rm = false;
        carUp.map((n) => {
            if (n.id === id && (n.cantidad > 1 || remove) && n.cantidad > 0) {
                n.cantidad--;
                rm = n.cantidad === 0;
            }
            return n;
        });
        
        
        if (rm) {
            dispatch({ type: REMOVE_ITEM, payload: id });
        } else {
            dispatch({ type: UPDATE_CAR, payload: carUp });
        }
        setTimeout(() => {
            target.classList.remove("minifySVG");
        }, 100);
       
    };
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style={ canRemove ? {fill: `var(--c2)`}  : undefined  }
            onClick={canRemove ? undefined : (e)=>onclick(e.target)}
        >
            <path d="M4,5.5 L4,19.5 C4,20.3284271 4.67157288,21 5.5,21 L19.5,21 C20.3284271,21 21,20.3284271 21,19.5 L21,5.5 C21,4.67157288 20.3284271,4 19.5,4 L5.5,4 C4.67157288,4 4,4.67157288 4,5.5 Z M3,5.5 C3,4.11928813 4.11928813,3 5.5,3 L19.5,3 C20.8807119,3 22,4.11928813 22,5.5 L22,19.5 C22,20.8807119 20.8807119,22 19.5,22 L5.5,22 C4.11928813,22 3,20.8807119 3,19.5 L3,5.5 Z M8.5,13 C8.22385763,13 8,12.7761424 8,12.5 C8,12.2238576 8.22385763,12 8.5,12 L16.5,12 C16.7761424,12 17,12.2238576 17,12.5 C17,12.7761424 16.7761424,13 16.5,13 L8.5,13 Z" />
        </svg>
    );
};

export default ButtonMinus;
