import React, { useEffect, Component } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import Countdown from "react-countdown";
import Services from "../../services/Services";

// Random component
const Completionist = ({ redirect, overTime }) => {
    const { t } = useTranslation();
    useEffect(() => {
        if (typeof overTime === "function") {
            overTime();
        }
    }, []);

    const closeModal = () => {
        let seats = JSON.parse(localStorage.getItem("reservationSeats"));
        seats.forEach( (item,key) =>{
            if ( document.getElementsByTagName("iframe")[0] && document.getElementsByTagName("iframe")[0].contentWindow) {
                document.getElementsByTagName("iframe")[0].contentWindow.postMessage(item.ShapeId.toString(), "*"); //desmarcar puesto en el mapa SIMPP
            }
        });
        if(redirect){
            setTimeout(() => {
                window.location.href = redirect
            }, 300);
        }
    }
    {console.log('ReservaExpirada desde timer')}
    return (
        <div
            className="modal fade modalAlert in"
            id="modalExpired"
            role="dialog"
            style={{ display: "block", paddingRight: "17px" }}
        >
            <div
                className="modal-dialog modal-dialog-centered modal-md"
                role="document"
            >
                <div className="modal-content">
                    <div className="conten-expired clear-fl">
                        <div className="top">
                            <i className="icon-timer color-b"></i>
                        </div>
                        <div className="content">
                            <div className="title color-titulos">
                                {t("timeExpired")}
                            </div>
                            <p className="color-texto-contenido">
                                {t("timeAvailableExpired")}
                            </p>
                            <a onClick={(e) => {
                                    e.preventDefault();
                                    closeModal();
                                    window.location.reload();
                                }}
                                href=""
                                data-dismiss="modal"
                                aria-label="Close"
                                className="color-ppal hover-text-ppal">
                                {t("tryAgain").toUpperCase()}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};



class Timer extends Component {
    state = {
        datess: Date.now() + 1200000
    }
    // Renderer callback with condition
    renderer = ({ hours, minutes, seconds, completed }) => {
      
        const { global, t, redirect, overTime, event } = this.props;
        if (completed) {
            
            if (redirect) {
                let seats = JSON.parse(localStorage.getItem("reservationSeats"));
                seats.forEach( (item,key) =>{
                    if ( document.getElementsByTagName("iframe")[0] && document.getElementsByTagName("iframe")[0].contentWindow) {
                        document.getElementsByTagName("iframe")[0].contentWindow.postMessage(item.ShapeId.toString(), "*"); //desmarcar puesto en el mapa SIMPP
                    }
                });
                document.location.href = redirect;
                return null;
            } else {
                return (
                    <Completionist redirect={redirect} overTime={overTime} />
                );
            }
        } else {
            // Render a countdown
            if (minutes > 6) {
                return (
                    <figure className="logo-ext">
                        <img src={global.logo} alt="" />
                    </figure>
                );
            }
            return (
                <div id="counter" className="flex_show">
                    <div className="wrap">
                        <p style={{textAlign: "center", paddingRight: "15px"}}>
                            <span className="color-texto-contenido">
                                {t("labelExpires")}
                            </span>
                            <b
                                className={`${
                                    minutes === 0 && seconds <= 30
                                        ? "parpadea"
                                        : "color-ppal"
                                }`}
                                style={{ opacity: 1 }}
                            >
                                {minutes}:
                                {seconds < 10 ? `0${seconds}` : seconds}
                            </b>
                        </p>
                    </div>
                </div>
            );
        }
    };
    
    render() {
       
        return (
            <Countdown date={this.state.datess} renderer={this.renderer} />
        );
    }
}

export default withTranslation()(React.memo(Timer));
